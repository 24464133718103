<template>
   <div class="container">
      <div class="content">Hello world!</div>

      <div class="footer">
         <el-link href="https://beian.miit.gov.cn/" type="info" :underline="false">陕ICP备19018447号-1</el-link>
      </div>
   </div>
</template>
   
<script>
export default {
   
}
</script>

<style scoped>

.container{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
   padding: 6px;
   box-sizing: border-box;
}

.content{
   font-size: 50px;
   margin: auto;
}

</style>