import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sidoc-top',
    name: 'sidoc-top',
    component: () => import('../views/SidocTop.vue')
  },
  {
    path: '/sidoc-vip',
    name: 'sidoc-vip',
    component: () => import('../views/SidocVip.vue')
  }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
})

export default router
