<template>
  <div id="app">
      <sidoc-vip v-if="hostname=='sidoc.vip' || hostname=='www.sidoc.vip'"></sidoc-vip>
      <sidoc-top v-else-if="hostname=='sidoc.top' || hostname=='www.sidoc.top'"></sidoc-top>
      <router-view v-else />
  </div>
</template>

<script>

import sidocVip from "./views/SidocVip.vue"
import sidocTop from "./views/SidocTop.vue"

export default {
    components:{
          sidocVip,
          sidocTop,
    },
    data(){
        return {
            env:process.env.NODE_ENV,
            hostname:window.location.hostname
        }
    },
    mounted(){
    },
    methods: {
        // 判断是否为IP
        isIp(value){
            let re=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式
            if(re.test(value)){
                return true;
            }
            return false;
        }
    }
}
</script>

<style>

html,body,#app{
   width: 100%;
   height: 100%;
   padding: 0px;
   margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  color: #2c3e50;
}


</style>
