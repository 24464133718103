<template>
  <div class="container">

    <h2>开发测试</h2>

    <div style="margin: 20px 2px;">{{ userAgent }}</div>

     <div class="projectList" v-loading="isLoading">
        <div class="item" v-for="(item,index) in data" :key="index" v-bind:style="{ cursor:item.path?'pointer':'text'}">
           <div class="name" @click="openUrl(item.path)">{{item.name}}</div>
           <div v-if="item.path" class="copy" @click.stop="copy(item.path)">复制链接</div>
           <div class="refresh-cdn" @click="refreshCdn(item.domain)">刷新DCDN</div>
           <div class="remark">{{item.remark}}</div>
        </div>
     </div>

     <div class="footer">©2022 YANGWW 512113110@QQ.COM</div>
  </div>
</template>

<script>

export default {
    name: 'Home',
    data(){
        return {
            isLoading:false,
            data:[
                // 前端项目
                { name:"0-common-statics",domain:"http://dev.nwbd.net/",remark:"公共静态项目" },
                { name:"nwbd.tdcms.vip_Mmb2e4",path:"http://www.nwbd.net/",domain:"http://www.nwbd.net/,http://nwbd.net/",remark:"公司官网" },
                { name:"4-web-canvas-webgl", path:"http://4.dev.nwbd.net", domain:"http://dev.nwbd.net/",remark:"Canvas / WebGL / Three.js学习" },
                { name:"5-amap-demo", path:"http://5.dev.nwbd.net",domain:"http://5.dev.nwbd.net/", remark:"电子围栏测试"  },
                { name:"6-geographic-coordinates", path:"http://6.dev.nwbd.net",domain:"http://6.dev.nwbd.net/", remark:"地理坐标转换、拾取"  },
                { name:"7-dong-meng", path:"http://7.dev.nwbd.net/app/login.html", domain:"http://7.dev.nwbd.net/",remark:"东盟拉力赛"  },
                { name:"8-gismap-cesium", path:"http://8.dev.nwbd.net/",domain:"http://8.dev.nwbd.net/", remark:"Cesium学习测试"  },
                { name:"9-model-calculation-tool", path:"http://9.dev.nwbd.net/", domain:"http://9.dev.nwbd.net/",remark:"模型计算工具"  },
                { name:"9-model-calculation-tool-gray", path:"http://9-gray.dev.nwbd.net/", domain:"http://9-gray.dev.nwbd.net/",remark:"模型计算工具-gray"  },
                { name:"11-random-selection", path:"http://11.dev.nwbd.net/",domain:"http://11.dev.nwbd.net/", remark:"随机抽奖小工具"  },
                { name:"13-countdown", path:"http://13.dev.nwbd.net/",domain:"http://13.dev.nwbd.net/", remark:"述职倒计时"  },
                { name:"5000-vue3-app", path:"http://5000.dev.nwbd.net/", domain:"http://5000.dev.nwbd.net/",remark:"Vue3-App"  },
                { name:"5000-vue3-face-recognition", path:"https://5000-face.dev.nwbd.net/", domain:"http://5000-face.dev.nwbd.net/",remark:"Vue3人脸识别测试"  },
                { name:"5310-beidou-referee", path:"http://5310.dev.nwbd.net/",domain:"http://5310.dev.nwbd.net/", remark:"电子裁评系统客户演示DEMO"  },

                // 其它页面
                // { name:"sidoc.top", path:"/sidoc-top", remark:"sidoc.top首页"  },
                // { name:"sidoc.vip", path:"/sidoc-vip", remark:"sidoc.vip首页"  },
            ],
            userAgent:navigator.userAgent
        }
        
    },
    mounted(){
        
    },
    methods: {
        copy(link){
            // let url = window.location.origin + link;

            // 将文本写入到剪切板（此为新一代复制、粘贴方法，仅支持HTTPS网页，详见:https://developer.mozilla.org/zh-CN/docs/Web/API/Clipboard）
            // navigator.clipboard.writeText(url);
            navigator.clipboard.writeText(link);

            this.$message({
                message: '复制成功！',
                type: 'success'
            });
        },
        openUrl(uri){
            if(uri){
                window.open(uri,"_blank");
            }
        },
        refreshCdn(url){

            // 确认提示
            if(!window.confirm("此操作会将DCDN边缘节点上的资源标记为失效，当再次访问资源时，会导致DCDN直接回源，从而降低缓存命中率，是否继续？")) return;

            // 鉴权
            let token = localStorage.getItem("0-common-statics-token");
            if(!token){
                alert("权限不足，请联系管理员在您的浏览器localStorage中置入密钥");
                return;
            }

            // 执行
            this.isLoading = true;
            fetch(`https://service.admin.sidoc.cn/index/aliyunDcdnRefreshUrl?token=${token}&url=${url}&account=nwbd`,{
                method: 'GET',
            }).then(response => {
                return response.json();  // 将请求结果转为json
            }).then(res => {
                if(res.code == 0){
                    this.$message({ message: '刷新成功！', type: 'success' });
                }else{
                    this.$message({ message: '刷新失败！', type: 'error' });
                }
                this.isLoading = false;
            });
        }
    }

}
</script>

<style scoped>

.container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 20px;
   padding-bottom:4px;
   box-sizing: border-box;
   height:100%;
}

.projectList{
   display: flex;
   flex-direction: column;
   padding:0px 16px;
   width: 50%;
   min-width: 700px;
}
.projectList>.item{
   display: flex;
   align-items: center;
   padding: 10px;
   border-bottom: 1px rgb(240, 240, 240) solid;
   font-size: 18px;
   font-weight:400;
   position: relative;
}
.projectList>.item:hover{
   background-color: rgba(240, 240, 240, 0.822);
}
.projectList>.item>.name{
   color:#1077e2;
   cursor: pointer;
}
.projectList>.item>.copy{
   color:gray;
   font-size: 15px;
   margin-left: 6px;
   cursor: pointer;
}

.projectList>.item>.refresh-cdn{
    position: absolute;
    left: 50%;
    font-size: 15px;
    color:#1077e2;
    cursor: pointer;
    display: none;
}
.projectList>.item:hover .refresh-cdn{
    display: flex;
}

.projectList>.item>.remark{
   margin-left: auto;
   font-size: 16px;
}


.footer{
   margin-top: auto;
   padding-top: 20px;
   font-size: 14px;
   color: gray;
}

</style>
